import React from 'react'
import { TGetListSourcesResponse } from '@/lib/hooks/api/useGetListSourcesRPC'
import { useListSources } from '@/lib/features/referenceData'
import {
  IDropDownData,
  TOptionTree,
  TOptionType,
  IOptionNode,
  TSelectionIDs
} from './types'
import {
  ADVERSE_MEDIA,
  UBO,
  UBOBackwardsCompatibilityAlias
} from '@/lib/constants/list_sources'

export const listSourceCategoryRanks: { [key: string]: number } = {
  Sanctions: 9,
  PEPs: 8,
  'Law Enforcement': 7,
  'Export Control': 6,
  Delisted: 5,
  'Contract Debarment': 4,
  'Elevated Risk': 3,
  'Regulatory Enforcement': 2,
  Custom: 1
}

export const listSourceRanks: { [key: string]: number } = {
  'US OFAC SDN': 2,
  'US OFAC Non-SDN': 1
}

// Arbitrary ID for the "All" dummy option.
export const ALL_OPTION_ID = 1

/**
 * sortFunc is generic function for sorting items based on a specified hard-coded ranking and
 * defaulting to lexicographical sort for unspecified items.
 *
 * @param {[key: string]: number} ranks - A map of hard-coded ranks to enforce.
 * @returns {void}
 */
export const sortFunc = (ranks: { [key: string]: number }) => {
  return (a: string, b: string): number => {
    const aRank = ranks[a] || 0
    const bRank = ranks[b] || 0

    if (aRank === bRank) {
      return a < b ? -1 : 1
    }

    return bRank - aRank
  }
}

/**
 * defaultSelectionOverrides defines default selection by category, list source, or list name.
 */
const defaultSelectionOverrides: Record<string, boolean> = {
  [ADVERSE_MEDIA]: false,
  [UBO]: false,
  [UBOBackwardsCompatibilityAlias]: false
}

/**
 * init initializes the option tree that represent the state of the drop down menus.
 *
 * @param {TGetListSourcesResponse} data - List sources data response from back-end API.
 * @param {boolean} selected - Whether to initialize all options as selected or unselected.
 * @returns {TOptionTree}
 */
const init = (
  data: TGetListSourcesResponse,
  selected = true,
  disabledCategories: string[] = []
): TOptionTree => {
  const options: TOptionTree = {
    option: {
      id: ALL_OPTION_ID,
      name: 'All',
      group: '',
      groupId: null,
      type: 'all',
      selected: selected,
      partial: disabledCategories.length > 0,
      hidden: false,
      disabled: false
    },
    subOptions: new Map()
  }

  for (const listSource of data) {
    const catId = listSource.category.id
    const lsId = listSource.id

    const categoryName = listSource.category.name
    const categoryDisabled = disabledCategories.includes(categoryName)
    const categorySelected =
      !categoryDisabled && (defaultSelectionOverrides[categoryName] ?? selected)

    const catOptionNode: IOptionNode = options.subOptions.get(catId) || {
      option: {
        id: catId,
        name: categoryName,
        group: 'Categories',
        groupId: null,
        type: 'category',
        selected: categorySelected,
        disabled: categoryDisabled,
        partial: false,
        hidden: false
      },
      subOptions: new Map()
    }
    if (!options.subOptions.has(catId)) {
      options.subOptions.set(catId, catOptionNode)
    }

    const listSourceName = listSource.name
    const listSourceOptionNode: IOptionNode = {
      option: {
        id: listSource.id,
        name: listSourceName,
        group: listSource.category.name,
        groupId: listSource.category.id,
        type: 'list-source',
        selected: categorySelected,
        partial: false,
        hidden: false,
        disabled: categoryDisabled
      },
      subOptions: new Map()
    }
    catOptionNode.subOptions.set(lsId, listSourceOptionNode)

    for (const list of listSource.lists) {
      const listName = list.name
      listSourceOptionNode.subOptions.set(list.id, {
        option: {
          id: list.id,
          name: listName,
          group: listSource.name,
          groupId: listSource.id,
          type: 'list',
          selected: categorySelected,
          partial: false,
          hidden: !selected,
          disabled: categoryDisabled
        },
        subOptions: new Map()
      })
    }

    // Sort lists.
    listSourceOptionNode.subOptions = new Map(
      [...listSourceOptionNode.subOptions.entries()].sort((a, b) =>
        sortFunc({})(a[1].option.name, b[1].option.name)
      )
    )
  }

  // Sort list sources.
  for (const catOptionNode of options.subOptions.values()) {
    catOptionNode.subOptions = new Map(
      [...catOptionNode.subOptions.entries()].sort((a, b) =>
        sortFunc(listSourceRanks)(a[1].option.name, b[1].option.name)
      )
    )
  }
  // Sort list source categories.
  options.subOptions = new Map(
    [...options.subOptions.entries()].sort((a, b) =>
      sortFunc(listSourceCategoryRanks)(a[1].option.name, b[1].option.name)
    )
  )

  return options
}

/**
 * processOptionToggle is a recursive function that toggles a set of options and returns a deep
 * copy of the option tree so that the state can be immutably updated in the hook. It strives
 * to do a single pass on the data structure and in the process update the state of parent and
 * children nodes of the affected nodes.
 *
 * @param {IOptionNode} oldOptionNode - The option node to process.
 * @param {TSelectionIDs} selections - IDs of the options to manipulate.
 * @param {boolean|undefined} selected - The specific state to set the option and sub-options to.
 *  Used to propagate a parent node state change to it's children.
 * @returns {IOptionNode}
 */
const processOptionToggle = (
  oldOptionNode: IOptionNode,
  selections: TSelectionIDs,
  selected: boolean | undefined = undefined,
  disabledCategories: string[] = []
): IOptionNode => {
  const newOptionNode = {
    option: { ...oldOptionNode.option },
    subOptions: new Map()
  }

  if (selected !== undefined) {
    newOptionNode.option.selected = selected
  } else if (
    selections[newOptionNode.option.type]?.has(newOptionNode.option.id)
  ) {
    newOptionNode.option.selected = !newOptionNode.option.selected

    // All option node is a special case, basically if there are any deselected options then
    // it should retain a partial state
    if (
      oldOptionNode.option.id === ALL_OPTION_ID &&
      disabledCategories.length > 0
    ) {
      newOptionNode.option.partial = true
    } else {
      newOptionNode.option.partial = false
    }

    selected = newOptionNode.option.selected
  }

  let subOptionsSelected = 0
  let subOptionPartial = false

  for (const [subId, oldSubOptionNode] of oldOptionNode.subOptions.entries()) {
    const newSubOptionNode = processOptionToggle(
      oldSubOptionNode,
      selections,
      disabledCategories.includes(oldSubOptionNode.option.name)
        ? false
        : selected
    )
    newOptionNode.subOptions.set(subId, newSubOptionNode)

    if (newSubOptionNode.option.selected) {
      subOptionsSelected++
    }

    if (newSubOptionNode.option.partial) {
      subOptionPartial = true
    }
  }

  // Determine partial state.
  if (newOptionNode.subOptions.size > 0) {
    if (subOptionsSelected === 0) {
      newOptionNode.option.selected = false
      newOptionNode.option.partial = false
    } else if (subOptionsSelected < newOptionNode.subOptions.size) {
      newOptionNode.option.selected = true
      newOptionNode.option.partial = true
    } else {
      newOptionNode.option.selected = true
      newOptionNode.option.partial = subOptionPartial
    }
  }

  // Unhide if selected.
  newOptionNode.option.hidden =
    newOptionNode.option.hidden && !newOptionNode.option.selected

  return newOptionNode
}

/**
 * pruneLists deep copies the option tree so that state can be updated immutably and in the process
 * marks lists as hidden when their entire parent list source is unselected.
 *
 * @param {TOptionTree} options
 * @returns {TOptionTree}
 */
const pruneLists = (options: TOptionTree): TOptionTree => {
  const newOptions = {
    option: { ...options.option },
    subOptions: new Map()
  }

  for (const [catId, categoryOptionNode] of options.subOptions.entries()) {
    const newCategoryOptionNode = {
      option: { ...categoryOptionNode.option },
      subOptions: new Map()
    }

    for (const [
      lsId,
      listSourceOptionNode
    ] of categoryOptionNode.subOptions.entries()) {
      const newListSourceOptionNode = {
        option: { ...listSourceOptionNode.option },
        subOptions: new Map()
      }

      for (const [
        listId,
        listOptionNode
      ] of listSourceOptionNode.subOptions.entries()) {
        const newListOptionNode = {
          option: {
            ...listOptionNode.option,
            hidden: !newListSourceOptionNode.option.selected
          },
          subOptions: new Map()
        }
        newListSourceOptionNode.subOptions.set(listId, newListOptionNode)
      }
      newCategoryOptionNode.subOptions.set(lsId, newListSourceOptionNode)
    }
    newOptions.subOptions.set(catId, newCategoryOptionNode)
  }

  return newOptions
}

/**
 * useListSourcesDropDownOptions is a hook for managing the state of the list sources, lists, and
 * list source categories drop-down menus. The state of these three menus is controled centrally in
 * this hook because their state is intertwined. The state is stored in a tree/nested map data
 * structure which preserves the hierarchy of the menu options i.e. selecting a category selects all
 * list sources and lists under that category.
 *
 * @returns {IDropDownData}
 */
export const useListSourcesDropDownOptions = (
  selections: TSelectionIDs = {},
  disabledCategories: string[] = []
): IDropDownData => {
  const { status, data } = useListSources()
  const [options, setOptions] = React.useState<TOptionTree>({
    option: {
      id: ALL_OPTION_ID,
      name: 'All',
      group: '',
      groupId: null,
      type: 'all',
      selected: true,
      partial: disabledCategories.length > 0,
      hidden: false,
      disabled: false
    },
    subOptions: new Map()
  })

  React.useEffect(() => {
    if (status === 'success' && data !== undefined) {
      const selectedByDefault = Object.keys(selections).length === 0
      setOptions(
        pruneLists(
          processOptionToggle(
            init(data, selectedByDefault, disabledCategories),
            selections,
            undefined,
            disabledCategories
          )
        )
      )
    }
  }, [data, status])

  const toggle = React.useCallback(
    (type: TOptionType, id: number) => {
      setOptions(
        processOptionToggle(
          options,
          { [type]: new Set([id]) },
          undefined,
          disabledCategories
        )
      )
    },
    [options]
  )

  const prune = React.useCallback(() => {
    setOptions(pruneLists(options))
  }, [options])

  const clear = React.useCallback(() => {
    setOptions(
      processOptionToggle(options, { all: new Set([ALL_OPTION_ID]) }, false)
    )
  }, [options])

  const anySelected = React.useCallback(() => {
    return options.option.selected
  }, [options])

  return {
    status,
    options,
    toggle,
    prune,
    clear,
    setOptions,
    anySelected
  }
}

type TParsedListSourcesOptions = {
  listSourceCategoriesParam: string[]
  listSourcesParam: { [key: string]: string[] }
  allSelectedListSourceCategories: string[]
}

/**
 * parseListSourcesOptions parses an options tree and returns information about selected list
 * sources that can be consumed more easily in certain use cases.
 *
 * It returns a list of list source categories to pass to the search query. This only includes
 * categories that are fully selected and only when not all list sources are selected.
 *
 * It also returns an object of list sources mapping to a list of list names that are selected. As
 * with categories, list sources and lists are only specified when that option is in a partial state.
 *
 * Lastly it returns a list of all selected list source categories. This information is passed
 * along to Google analytics.
 *
 * @param {TOptionTree} options - The option tree to parse.
 * @returns {string[], { [key: string]: string[] }, string[]}
 */
export const parseListSourcesOptions = (
  options: TOptionTree
): TParsedListSourcesOptions => {
  const listSourceCategoriesParam: string[] = []
  const listSourcesParam: { [key: string]: string[] } = {}
  const allSelectedListSourceCategories: string[] = []
  const categories = options.subOptions.values()

  for (const categoryOptionNode of categories) {
    if (
      categoryOptionNode.option.selected &&
      !categoryOptionNode.option.partial
    ) {
      listSourceCategoriesParam.push(categoryOptionNode.option.name)
    }

    // Add for google Analytics
    if (categoryOptionNode.option.selected) {
      allSelectedListSourceCategories.push(categoryOptionNode.option.name)
    }

    for (const listSourceOptionNode of categoryOptionNode.subOptions.values()) {
      if (
        categoryOptionNode.option.partial &&
        listSourceOptionNode.option.selected
      ) {
        listSourcesParam[listSourceOptionNode.option.name] = []
        if (listSourceOptionNode.option.partial) {
          for (const listOptionNode of listSourceOptionNode.subOptions.values()) {
            if (listOptionNode.option.selected) {
              listSourcesParam[listSourceOptionNode.option.name].push(
                listOptionNode.option.name
              )
            }
          }
        }
      }
    }
  }

  return {
    listSourceCategoriesParam,
    listSourcesParam,
    allSelectedListSourceCategories
  }
}

/**
 * selectedListSourcesOptionsIDs traverses the options tree and returns a list of category IDS, a
 * list of list source IDS, and a list of list IDs that have been selected. It does so with minimal
 * required specificity.
 *
 * For example, if a category is fully selected, its ID will be included in listSourceCategoryIDs,
 * but its constituent list source IDs won't be included in listSourceIDs.
 *
 * However, if a subset of list sources in a category are selected then the category ID will not be
 * included in listSourceCategoryIDs, but the selected list source IDs will be included in
 * listSourceIDs.
 *
 * The optional rollUpLists parameter overrides this, and allows any subset of selected lists to pass
 * only their listSourceId.
 *
 * @param {TOptionTree} options - The option tree to parse.
 * @param {bool} rollUpLists - If individual lists should be rolled up to their listSource
 * @returns TSelectedListSourceIDs
 */
export const selectedListSourcesOptionsIDs = (
  options: TOptionTree,
  rollUpLists = false
): Omit<TSelectionIDs, 'all'> => {
  const listSourceCategoryIDs: Set<number> = new Set([])
  const listSourceIDs: Set<number> = new Set([])
  const listIDs: Set<number> = new Set([])

  for (const categoryOptionNode of options.subOptions.values()) {
    if (!categoryOptionNode.option.selected) {
      continue
    } else if (!categoryOptionNode.option.partial) {
      listSourceCategoryIDs.add(categoryOptionNode.option.id)
      continue
    }

    for (const listSourceOptionNode of categoryOptionNode.subOptions.values()) {
      if (!listSourceOptionNode.option.selected) {
        continue
      } else if (!listSourceOptionNode.option.partial) {
        listSourceIDs.add(listSourceOptionNode.option.id)
        continue
      }

      for (const listOptionNode of listSourceOptionNode.subOptions.values()) {
        if (listOptionNode.option.selected) {
          const groupId = listOptionNode.option.groupId

          if (rollUpLists && groupId) {
            listSourceIDs.add(groupId)
          } else {
            listIDs.add(listOptionNode.option.id)
          }
        }
      }
    }
  }

  return {
    category: listSourceCategoryIDs,
    'list-source': listSourceIDs,
    list: listIDs
  }
}

export default useListSourcesDropDownOptions
